export interface Dimensions {
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
}

export interface Price {
  net: number;
  vat?: number;
  gross: number;
}

export enum DeliveryType {
  P2P = 'P2P' as any,
  D2P = 'D2P' as any,
  P2D = 'P2D' as any,
  D2D = 'D2D' as any,
}

export interface Parcel {
  dimensions?: Dimensions;
  insuranceValue?: number;
  price?: Price;
  additionalServices?: AdditionalService[];
}

export interface AdditionalService {
  serviceName: string;
  price: Price;
  changed?: boolean;
}
// WE NEED MULTIPLE MODELS FOR THE SAME PURPOSE, CUS OF VALIDATION SERVICE
export interface ValidationAdditionalService {
  type: string;
  value: number | null;
}

export interface OrderAdditionalService {
  name: string;
  value: number | null;
}

export interface UnavailableReason {
  messageCode: string;
  message?: string;
  field?: string;
  value?: string;
}

export interface PriceResponse {
  operatorProduct: string;
  operatorName: string;
  deliveryType: DeliveryType;
  price?: Price;
  parcel: Parcel;
  unavailableReasons?: UnavailableReason[];
  additionalServices?: AdditionalService[];
  available?: boolean;
}

export interface OfferItem {
  operator: string;
  serviceType: DeliveryType;
}

export interface CountryScopedOffer {
  country: string;
  priceLists: OfferItem[];
}

export interface PriceRequest {
  senderFirstName?: string;
  senderLastName?: string;
  senderPhoneNumber?: string;
  senderEmail?: string;
  senderStreet?: string;
  senderBuildingNumber?: string;
  senderFlatNumber?: string;
  senderPostCode?: string;
  senderCity?: string;
  countryCode?: string;

  receiverFirstName?: string;
  receiverLastName?: string;
  receiverPhoneNumber?: string;
  receiverEmail?: string;
  receiverStreet?: string;
  receiverBuildingNumber?: string;
  receiverFlatNumber?: null;
  receiverPostCode?: string;
  receiverCity?: string;
  receiverCountryCode?: string;

  operatorName?: string;
  deliveryType: DeliveryType;
  additionalServices?: ValidationAdditionalService[];
  parcels: Parcel[];
}
