import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/service';
import { Configuration } from '../../model/configuration.model';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent {
  config$: Observable<Configuration>;

  constructor(private configService: ConfigService) {
    this.config$ = this.configService.config$;
   }
}
