<div class="main-header-wrapper">
  <ng-container *ngIf="(config$ | async)?.setup?.sectionsConfiguration?.returnForm; else shippingForm">
    <div class="main-header-container">
      <h1 class="main-header">{{ 'FORM.MAIN_RETURN_HEADER' | translate }}</h1>
      <p class="sub-header">{{ 'FORM.MAIN_RETURN_HEADER_SUBTEXT' | translate }}</p>
    </div>
    <img
      
      class="bp-logo-mini"
      src="/assets/images/alsendo_logo.svg"
      [attr.alt]="'PORTAL_NAME' | translate"
    >
  </ng-container>
  <ng-template #shippingForm>
    <div class="main-header-container">
      <h1 class="main-header">{{ 'FORM.MAIN_HEADER' | translate }}</h1>
    </div>
  </ng-template>
</div>
