import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/service';
import { Configuration } from 'src/app/shared/model/configuration.model';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {
  config$: Observable<Configuration>

  constructor(private configService: ConfigService) { 
    this.config$ = this.configService.config$;
  }
}
