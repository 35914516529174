<div class="row" *ngIf="fieldVisibility?.['name']">
  <st-input
    [control]="customerFormControls.name"
    label="{{ namespacedCustomerNameField | translate }}"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('Name') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('Name') }}"
    class="col col-11 col-md-16"
  >
  </st-input>
</div>
<div class="row" *ngIf="fieldVisibility?.['street'] || fieldVisibility?.['buildingNumber'] || fieldVisibility?.['flatNumber']">
  <st-input
    [control]="customerFormControls.street"
    label="{{ 'FORM.ADDRESS_DATA.STREET_LABEL' | translate }}"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('Street') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('Street') }}"
    class="col col-11 col-md-10"
  >
  </st-input>
  <st-input
    [control]="customerFormControls.buildingNumber"
    label="{{ 'FORM.ADDRESS_DATA.BUILDING_FLAT_LABEL' | translate }}"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('BuildingNumber') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('BuildingNumber') }}"
    class="col col-2 col-md-3"
  >
  </st-input>
  <div class="col col-0 inter-input-slash">
    <span class="">/</span>
  </div>
  <st-input
    [control]="customerFormControls.flatNumber"
    label=""
    name="{{ getNamespacedCustomerFieldName('FlatNumber') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('FlatNumber') }}"
    class="col col-2 col-md-3"
  >
  </st-input>
</div>
<div class="row" *ngIf="fieldVisibility?.['postCode'] || fieldVisibility?.['city']">
  <st-input
    [control]="customerFormControls.postCode"
    label="{{ 'FORM.ADDRESS_DATA.POSTAL_CODE_LABEL' | translate }}"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('PostCode') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('PostCode') }}"
    class="col col-6"
  >
  </st-input>
  <st-input
    [control]="customerFormControls.city"
    label="{{ 'FORM.ADDRESS_DATA.CITY_LABEL' | translate }}"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('City') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('City') }}"
    class="col col-10"
  >
  </st-input>
</div>
<div class="row" *ngIf="fieldVisibility?.['phoneNumber']">
  <st-input
  [control]="customerFormControls.phoneNumber"
  label="{{ 'FORM.ADDRESS_DATA.PHONE_NUMBER_LABEL' | translate }}"
  isRequired="true"
    name="{{ getNamespacedCustomerFieldName('PhoneNumber') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('PhoneNumber') }}"
    class="col col-11 col-md-16"
  >
  </st-input>
</div>
<div class="row" *ngIf="fieldVisibility?.['email']">
  <st-input
    [control]="customerFormControls.email"
    label="{{ 'FORM.ADDRESS_DATA.EMAIL_ADDRESS_LABEL' | translate }}"
    [hintText]="(config$ | async)?.setup?.sectionsConfiguration?.returnForm ? ('FORM.ADDRESS_DATA.EMAIL_SENDER_HINT' | translate) : ''"
    isRequired="true"
    name="{{ getNamespacedCustomerFieldName('Email') }}"
    attr.data-test="{{ getNamespacedCustomerFieldName('Email') }}"
    class="col col-11 col-md-16"
  >
  </st-input>
</div>
<div class="row" *ngIf="fieldVisibility?.['pointCode']">
  <st-input
    [control]="customerFormControls.pointCode"
    label="{{ namespacedCustomerPointCodeField | translate }}"
    isRequired="true"
    attr.data-test="{{ namespacedCustomerPointCodeField }}"
    class="col col-11 col-md-16"
  >
  </st-input>
</div>
