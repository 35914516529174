export interface FieldMapping {
  requiredFields: string[];
  visibleFields: string[];
}

export interface OperatorMappings {
  [deliveryType: string]: FieldMapping;
}

export interface ReceiverFieldMappings {
  [operator: string]: OperatorMappings;
}

export const receiverFieldMappings: ReceiverFieldMappings = {
  'INPOST': {
    'P2P': {
      requiredFields: ['name', 'email', 'phoneNumber', 'pointCode'],
      visibleFields: ['name', 'email', 'phoneNumber', 'pointCode']
    },
    'P2D': {
      requiredFields: ['name', 'email', 'phoneNumber', 'street', 'buildingNumber', 'postCode', 'city'],
      visibleFields: ['name', 'email', 'phoneNumber', 'street', 'buildingNumber', 'flatNumber', 'postCode', 'city']
    }
  },
};
