import { FieldVisibility } from './field-visibility.model';

export const defaultFieldVisibility: FieldVisibility = {
  name: true,
  street: true,
  buildingNumber: true,
  flatNumber: true,
  postCode: true,
  city: true,
  phoneNumber: true,
  email: true,
  pointCode: false
};
