export const defaultRequiredFields: string[] = [
  'name',
  'street',
  'buildingNumber',
  'postCode',
  'city',
  'phoneNumber',
  'email'
];

export const excludedOperatorsForPostingCode: string[] = ["INPOST"];
