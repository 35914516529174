<!-- TODO: dynamic switching mechanism -->
<div
  id="header"
  class="wrapper header"
>
  <img
    class="logo"
    data-test="header-logo"
    src="/assets/images/alsendo_logo.svg"
    alt="{{'PORTAL_NAME' | translate | lowercase}}"
  >
  <h2>{{(config$ | async)?.setup?.header?.title}}</h2>
  <img
    *ngIf="(config$ | async)?.branding?.logoUrl"
    class="logo"
    data-test="header-client-logo"
    [src]="(config$ | async)?.branding?.logoUrl"
    alt="{{((config$ | async)?.setup?.header?.title || ('PRODUCT_NAME' | translate)) | lowercase}}"
  >
</div>
